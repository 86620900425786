import { Button, FormItem, Input, Modal, ModalSection, Text } from '@mm/company-ui'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { recoverPassword } from '../auth'

type FormFields = {
  email: string
}

export type ForgotPasswordModalProps = {
  isOpen: boolean
  onRequestClose: () => void
}

export const ForgotPasswordModal = ({ isOpen, onRequestClose }: ForgotPasswordModalProps) => {
  const [errorMessage, setErrorMessage] = React.useState('')
  const [success, setSuccess] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const form = useForm<FormFields>({
    mode: 'onTouched',
  })

  const onSubmit = form.handleSubmit(({ email }) => {
    setErrorMessage('')
    setSuccess(false)
    setLoading(true)

    recoverPassword({
      email,
      onDone: (error) => {
        setLoading(false)
        if (!error) setSuccess(true)
        if (error && error.description) setErrorMessage(error.description)
      },
    })
  })

  return (
    <Modal isOpen={isOpen} title="Forgot Password" showCloseIcon onRequestClose={onRequestClose}>
      <FormProvider {...form}>
        <ModalSection column>
          <FormItem
            name="email"
            label="Your e-mail"
            rules={{
              required: 'Email is required',
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Invalid email format.',
              },
            }}
          >
            <Input variant="default" placeholder="Email" />
          </FormItem>
        </ModalSection>
        <ModalSection row grow justify="flex-end" gap={1.5} sx={{ paddingTop: 2 }}>
          <Button size="small" onClick={onRequestClose}>
            Cancel
          </Button>
          <Button size="small" variant="accent" loading={loading} onClick={onSubmit}>
            Send me instructions
          </Button>
        </ModalSection>
        {errorMessage && (
          <Text as="p" sx={{ color: 'system.red', fontSize: 0, py: 2, px: 2.5 }}>
            {errorMessage}
          </Text>
        )}
        {success && (
          <Text as="p" sx={{ color: 'system.green', fontSize: 0, py: 2, px: 2.5 }}>
            We&apos;ve just sent you an email to reset your password. It may take a few minutes to arrive.
          </Text>
        )}
      </FormProvider>
    </Modal>
  )
}
