import { Text, Flex } from '@mm/company-ui'
import Image from 'next/image'
import React from 'react'
import { LoginForm } from '../modules/auth'
import { FrontFrame } from '../modules/navigation'

const LoginPage = () => {
  return (
    <FrontFrame title="Login" logoOnly>
      <Flex
        sx={{
          width: '100%',
          height: '100%',
          justifyContent: ['start', 'space-between'],
          flexDirection: ['column-reverse', 'row'],
          alignItems: ['start', 'center'],
        }}
      >
        <div
          sx={{
            width: ['100%', '750px'],
            marginTop: [4, -8],
            marginRight: [0, 8],
            marginLeft: [-2, 8],
            position: 'relative',
            zIndex: -1,
          }}
        >
          <Image alt="Login Image" src="/login.svg" layout="responsive" height={694} width={593} />
        </div>

        <div sx={{ width: ['100%', 400], maxWidth: 400, margin: 'auto' }}>
          <Text variant="jumbo" as="h1" sx={{ fontWeight: 'bold', fontSize: [4, 6], paddingBottom: 2 }}>
            Sign In
          </Text>
          <LoginForm />
        </div>
      </Flex>
    </FrontFrame>
  )
}

export default LoginPage
