import React from 'react'
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { Flex } from '../Flex'
import { Text } from '../Text'

export type FormItemProps = {
  children: React.ReactElement
  label?: string
  className?: string
}

export const FormItem = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  children,
  label,
  className,
  ...props
}: UseControllerProps<TFieldValues, TName> & FormItemProps) => {
  const {
    field,
    fieldState: { error, ...state },
  } = useController(props)

  return (
    <Flex column className={className}>
      {label && (
        <Text
          as="div"
          color="text"
          bold
          sx={{
            marginTop: 2,
            marginBottom: 1,
          }}
        >
          {label}
        </Text>
      )}
      {React.cloneElement(children, {
        ...field,
        ...state,
      })}
      {error && <Text sx={{ color: 'system-background-warning' }}>{error.message}</Text>}
    </Flex>
  )
}
