import { Button, ExternalLink, Flex, FormItem, Input, Text } from '@mm/company-ui'
import { useRouter } from 'next/router'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSignInWithCredentials } from '../auth'
import { ForgotPasswordModal } from './ForgotPasswordModal'

type FormFields = {
  email: string
  password: string
}
export const LoginForm = () => {
  const [forgotPasswordOpen, setForgotPasswordOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const router = useRouter()

  const returnTo = router.query['returnTo'] && String(router.query['returnTo'])
  const error = router.query['error'] && String(router.query['error'])

  const form = useForm<FormFields>({
    mode: 'onTouched',
  })

  const signInWithCredentials = useSignInWithCredentials()
  const onSubmit = form.handleSubmit(async ({ email, password }) => {
    setLoading(true)
    setErrorMessage('')

    signInWithCredentials({
      email,
      password,
      returnTo,
      onError: (error) => {
        setLoading(false)
        if (error && error.description) setErrorMessage(error.description)
      },
    })
  })

  return (
    <Flex column gap={2}>
      <form onSubmit={onSubmit}>
        <FormProvider {...form}>
          <FormItem
            name="email"
            label="Email"
            rules={{
              required: 'Email is required',
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Invalid email format.',
              },
            }}
          >
            <Input name="email" type="email" placeholder="Email" />
          </FormItem>

          <FormItem name="password" label="Password" rules={{ required: 'Password is required' }}>
            <Input name="password" type="password" placeholder="Password" />
          </FormItem>

          <Button
            type="submit"
            loading={loading}
            variant="accent"
            sx={{ width: '100%', marginTop: 2, marginBottom: 2, display: 'flex', justifyContent: 'center' }}
          >
            Log In
          </Button>

          <ExternalLink
            variant="secondary"
            onClick={() => {
              setForgotPasswordOpen(true)
            }}
          >
            Forgot Password?
          </ExternalLink>
          {errorMessage && (
            <Text as="p" sx={{ color: 'system.red', fontSize: 0 }}>
              {errorMessage}
            </Text>
          )}
          {error && (
            <Text as="p" sx={{ color: 'system.red', fontSize: 0 }}>
              {error}
            </Text>
          )}
        </FormProvider>
      </form>
      <ForgotPasswordModal
        isOpen={forgotPasswordOpen}
        onRequestClose={() => {
          setForgotPasswordOpen(false)
        }}
      />
    </Flex>
  )
}
